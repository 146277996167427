<template>
  <div class="body" style="width: 100%; height:100%; display: flex; flex-direction: column; align-items: center; ">
    <div style="width:100%; height:100px; background: #FAA887; box-sizing: border-box; padding-right: 5%; padding-left: 5%;
    display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
      <div style="display: flex; flex-direction: row; align-items: center; height: 100px;">
        <img src="../assets/logo.png" alt="" style="height:60px;">
        <div style="width: 81px; height: 28px; font-size: 28px; font-family: Helvetica; font-weight: bold;
          line-height: 28px; color: #FFFFFF; margin-left:18px;">UIdance</div>
      </div>
      <div style="display: flex; flex-direction: row; align-items: center; font-size: 16px;
      font-size: 16px; font-weight: 600; line-height: 30px; color: #FDFEFD;">
        <div style="border-bottom: 2px #FF7133 solid; cursor:pointer;">首页</div>
        <div style="margin-left: 54px; cursor:pointer;" @click="downloadHandler">下载</div>
        <div style="margin-left: 54px; cursor:pointer;" @click="openLoginupHandler">注册</div>
        <div style="margin-left: 54px; width: 107px; height: 45px; background: #F9855B; cursor:pointer;
        line-height: 45px;border-radius: 10px; text-align: center;" @click="openLoginHandler">登录</div>
      </div>
    </div>

    <div v-if="!closeLogin" style="position: fixed; top:0; left: 0; width:100%; height: 100%; background: #808080b3;z-index: 1000; display: flex; flex-direction: row; align-items: center; justify-content: center;">
       <div style="position: relative; height: 450px; display: flex; flex-direction: row; align-items: center; justify-content: center; background:#F3F6FF;">
         <img src="../assets/login.png" alt="" style="height: 100%;">
         <img src="../assets/close.png" alt="" style="position: absolute; right: 5px; top: 5px; height: 25px;" @click="closeHandler">
         <div style="position: relative;">
           <div v-if="isLogin" style="margin-bottom: 65px; font-weight: 400; font-size: 24px;color: #000000;">欢迎登录!</div>
           <div v-if="!isLogin" style="margin-bottom: 65px; font-weight: 400; font-size: 24px;color: #000000;">欢迎注册!</div>
           <div style="display: flex; flex-direction: row; align-items: center; justify-content: start;">
             <img src="../assets/username@2x.png" alt="" style="width: 17px;">
             <input type="text" placeholder="请输入用户名" style="margin-left:20px; border: 0; border-bottom: 1px; backgound: #F3F6FF; font-weight: 400; font-size: 12px; width: 200px; margin-right: 100px;">
           </div>
           <div style="display: flex; flex-direction: row; align-items: center; justify-content: start; margin-top:30px;">
             <img src="../assets/password@2x.png" alt="" style="width: 17px;">
             <input type="password" placeholder="请输入密码" style="margin-left:20px; border: 0; border-bottom: 1px; backgound: #F3F6FF; font-weight: 400; font-size: 12px; width: 200px; margin-right: 100px;">
           </div>
           <div v-if="!isLogin" style="display: flex; flex-direction: row; align-items: center; justify-content: start; margin-top:30px;">
             <img src="../assets/password@2x.png" alt="" style="width: 17px;">
             <input type="password" placeholder="请确认密码" style="margin-left:20px; border: 0; border-bottom: 1px; backgound: #F3F6FF; font-weight: 400; font-size: 12px; width: 200px; margin-right: 100px;">
           </div>
           <div v-if="isLogin" style="display: flex; flex-direction: row; align-items: center; justify-content: center; width:246px; height: 40px; background: rgb(250, 168, 135); margin-top: 50px;cursor:pointer;" @click="loginHandler">登录</div>
           <div v-if="!isLogin" style="display: flex; flex-direction: row; align-items: center; justify-content: center; width:246px; height: 40px; background: rgb(250, 168, 135); margin-top: 50px;cursor:pointer;" @click="logupHandler">注册</div>
           <div v-if="isLogin" style="font-weight: 400; font-size: 14px; color: #817D7D; margin-top: 20px; cursor:pointer;" @click="toLoginupHandler">点击这里去注册</div>
           <div v-if="!isLogin" style="font-weight: 400; font-size: 14px; color: #817D7D; margin-top: 20px; cursor:pointer;" @click="toLogininHandler">点击这里去登录</div>
         </div>
       </div>
    </div>

    <div style="position: relative; width:100%; height:573px; background: #FAA887; display: flex; flex-direction: column; align-items: center;" >
      <div style="width:1000px; display: flex; flex-direction: row; position: relative; margin-bottom: 100px;">
        <div>
          <div style="font-size: 76px; font-weight: bold; line-height: 82px; width: 456px; 
          padding-top: 111px; color: #FDFEFD;">提高团队效率 40%+</div>
          <div style="font-size: 24px; font-weight: 600; line-height: 32px; width: 455px;
          margin-top: 27px; color: #FDFEFD;">帮助团队降本增效 提高开发效率 节省沟通成本</div>
          <div style="width: 175px; height: 60px; background: #F9855B; 
            line-height: 60px;border-radius: 10px; text-align: center; color: #FDFEFD; 
            font-weight: 600; font-size: 24px; margin-top:70px; cursor:pointer;" @click="downloadHandler">下 载</div>
        </div>
        
        <!-- <div style="position: absolute; right:0px; top: 93px; background: #F9855B; 
        height:419px; width: 496px;"></div> -->
        <!-- <img src="../assets/img-1.png" alt="" style="position: absolute; right:0px; top: 93px; background: #F9855B; 
        height:419px; width: 496px; border-radius: 20px;">
        <img src="../assets/icon-ps-sketch-xd.png" style="position: absolute; right:300px; 
        top: 412px; height:206px; width: 362px;"> -->
        <img src="../assets/top-icon.png" style="position: relative; left: -85px; height:598px; width: 720px;">
      </div>
    </div>

    <div style="width:100%; height:100px; font-size: 60px; font-weight: bold; margin-top:120px;
    text-align: center; line-height: 70px; color: #262626;">开启 设计开发交付新纪元</div>
    <div style="width:100%; font-size: 20px; font-weight: 400; margin-top:12px;
    text-align: center; line-height: 24px; color: #A1A1AC;">支持源文件上传、一键导出切图、查看标注</div>

    <div style="width:100%; display: flex; flex-direction: column; align-items: center; margin-top:56px;">
      <div style="display: flex; flex-direction: row; align-items: center; ">
        <div style="width: 456px; height: 266px; background: #FFFFFF; border-radius: 8px;">
          <img src="../assets/icon-1.png" alt="" style="width:40; height:40px; margin-top: 45px; margin-left:40px;">
          <div style="font-size: 18px; font-weight: 500; line-height: 38px; 
          margin-top: 25px; margin-left:40px; color: #999999;">自动标注</div>
          <div style="font-size: 18px; font-weight: 400; line-height: 38px; 
          margin-top: 25px; margin-left:40px; color: #999999;">自动生成多尺寸、多格式切图</div>
          <div style="font-size: 18px; font-weight: 400; line-height: 38px; 
          margin-top: 6px; margin-left:40px; color: #999999;">实时更新画板，生成标注共享链接</div>
        </div>
        <div style="margin-left:30px; width: 456px; height: 266px; background: #FFFFFF; border-radius: 8px;">
          <img src="../assets/icon-2.png" alt="" style="width:40; height:40px; margin-top: 45px; margin-left:40px;">
          <div style="font-size: 18px; font-weight: 500; line-height: 38px; 
          margin-top: 25px; margin-left:40px; color: #999999;">自动切图</div>
          <div style="font-size: 18px; font-weight: 400; line-height: 38px; 
          margin-top: 25px; margin-left:40px; color: #999999;">前端工程师一键共享</div>
          <div style="font-size: 18px; font-weight: 400; line-height: 38px; 
          margin-top: 6px; margin-left:40px; color: #999999;">告别手动标注</div>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; align-items: center; margin-top:30px;">
        <div style="width: 456px; height: 266px; background: #FFFFFF; border-radius: 8px;">
          <img src="../assets/icon-3.png" alt="" style="width:40; height:40px; margin-top: 45px; margin-left:40px;">
          <div style="font-size: 18px; font-weight: 500; line-height: 38px; 
          margin-top: 25px; margin-left:40px; color: #999999;">文档管理</div>
          <div style="font-size: 18px; font-weight: 400; line-height: 38px; 
          margin-top: 25px; margin-left:40px; color: #999999;">需求、原型文档一键上传</div>
          <div style="font-size: 18px; font-weight: 400; line-height: 38px; 
          margin-top: 6px; margin-left:40px; color: #999999;">自动生成版本记录</div>
        </div>
        <div style="margin-left:30px; width: 456px; height: 266px; background: #FFFFFF; border-radius: 8px;">
          <img src="../assets/icon-4.png" alt="" style="width:40; height:40px; margin-top: 45px; margin-left:40px;">
          <div style="font-size: 18px; font-weight: 500; line-height: 38px; 
          margin-top: 25px; margin-left:40px; color: #999999;">链接分享</div>
          <div style="font-size: 18px; font-weight: 400; line-height: 38px; 
          margin-top: 25px; margin-left:40px; color: #999999;">无需本地压缩，邮箱发送</div>
          <div style="font-size: 18px; font-weight: 400; line-height: 38px; 
          margin-top: 6px; margin-left:40px; color: #999999;">链接分享 即使查看</div>
        </div>
      </div>

      <div style="position: relative; width:80%; margin-top:130px;">
        <div style="display: flex; flex-direction: column; align-items: center; position: absolute; right: 0px;">
          <div style="width: 450px; height: 80px; background: #E2E9FF; box-shadow: 12px 15px 18px rgba(0, 0, 0, 0.03); 
          border-top-left-radius: 10px; border-top-right-radius: 10px;"></div>
          <!-- <img src="../assets/icon-5.jpg" alt=""> -->
          <!-- <img src="../assets/gif_qietu.gif" alt="" style="height:365px; width:648px;"> -->
          <video autoplay controls loop muted src="https://uidance-1306486948.cos.ap-shanghai.myqcloud.com/video/qietu.mp4" alt="" style="height:365px; width:648px;">
          您的浏览器不支持 video 标签。
          </video>
        </div>
        <div style="font-size: 38px; font-weight: bold; line-height: 48px; color: #262626; margin-top:135px;">切图示例</div>
        <div style="font-size: 16px; font-weight: 400; line-height: 30px; margin-top: 27px; width: 309px;
        color: #999999;">设计师标注后，即可直接生成切图 随时调用随时下载</div>
        <div style="width: 130px; height: 45px; background: #647CFF; border-radius: 10px; text-align: center; 
        line-height: 45px; color: #FFFDFD; margin-top:42px; cursor:pointer;">查看更多</div>
      </div>

      <div style="position: relative; width:80%; margin-top:200px;">
        <div style="display: flex; flex-direction: column; position: absolute; right: 0px;">
          <div style="font-size: 38px; font-weight: bold; line-height: 48px; color: #262626; margin-top:135px;">查看标注示例</div>
          <div style="font-size: 16px; font-weight: 400; line-height: 30px; margin-top: 27px; width: 309px;
          color: #999999;">设计师标注后，即可直接生成标注 任意查看</div>
          <div style="width: 130px; height: 45px; background: #647CFF; border-radius: 10px; text-align: center; 
          line-height: 45px; color: #FFFDFD; margin-top:42px;cursor:pointer;">查看更多</div>
        </div>
        <div>
          <div style="width: 450px; height: 80px; background: #E2E9FF; box-shadow: 12px 15px 18px rgba(0, 0, 0, 0.03); 
            border-top-left-radius: 10px; border-top-right-radius: 10px;"></div>
          <!-- <img src="../assets/img-2.png" alt=""> -->
          <!-- <img src="../assets/gif_biaozhu.gif" alt="" style="height:365px; width:648px;"> -->
          <video autoplay controls loop muted src="https://uidance-1306486948.cos.ap-shanghai.myqcloud.com/video/biaozhu.mp4" alt="" style="height:365px; width:648px;">
          您的浏览器不支持 video 标签。
          </video>
        </div>
      </div>

      <div style="position: relative; width:80%; margin-top:130px;">
        <div style="display: flex; flex-direction: column; align-items: center; position: absolute; right: 0px;">
          <div style="width: 450px; height: 80px; background: #E2E9FF; box-shadow: 12px 15px 18px rgba(0, 0, 0, 0.03); 
          border-top-left-radius: 10px; border-top-right-radius: 10px;"></div>
          <!-- <img src="../assets/icon-5.jpg" alt=""> -->
          <!-- <img src="../assets/gif_code.gif" alt="" style="height:365px; width:648px;"> -->
          <video autoplay controls loop muted src="https://uidance-1306486948.cos.ap-shanghai.myqcloud.com/video/daima.mp4" alt="" style="height:365px; width:648px;">
          您的浏览器不支持 video 标签。
          </video>
        </div>
        <div style="font-size: 38px; font-weight: bold; line-height: 48px; color: #262626; margin-top:135px;">查看代码</div>
        <div style="font-size: 16px; font-weight: 400; line-height: 30px; margin-top: 27px; width: 309px;
        color: #999999;">可直接生成代码，支持Android、iOS、Web，随时调用随时下载</div>
        <div style="width: 130px; height: 45px; background: #647CFF; border-radius: 10px; text-align: center; 
        line-height: 45px; color: #FFFDFD; margin-top:42px;cursor:pointer;">查看更多</div>
      </div>

      <!-- <div style="position: relative; margin-top:300px; margin-bottom: 200px; display: flex; flex-direction: row; align-items: center; justify-content: center;">
        <img src="../assets/checkmore.png" alt="" style="width:100%;">
        <div style="position: absolute; display: flex; flex-direction: row; align-items: center; justify-content: space-between; height: 460px; width: 65%; margin-top: 100px;">
          <div style="height: 120px;">
            <div style="font-size: 45px; font-weight: bold; line-height: 70px; color: #262626;">快来体验吧</div>
            <div style="font-size: 16px; font-weight: 600; line-height: 24px; margin-top: 21px; color: #FFF">创建一个属于您的账户或者直接登录</div>
            <div style="width: 130px; height: 45px; background: #647CFF; border-radius: 10px; text-align: center; 
          line-height: 45px; color: #FFFDFD; margin-top:42px;cursor:pointer;" @click="openLoginHandler">注册</div>
          </div>
        </div>
      </div> -->

      <div style="position: relative; margin-top:300px; margin-bottom: 100px; display: flex; flex-direction: column; 
      align-items: center; justify-content: center; width: 100%; height: 500px; ">
            <div style="font-size: 45px; font-weight: bold; line-height: 70px; color: #262626;">快来体验吧</div>
            <div style="font-size: 16px; font-weight: 600; line-height: 24px; margin-top: 21px; color: gray;">创建一个属于您的账户或者直接登录</div>
            <div style="width: 130px; height: 45px; background: #647CFF; border-radius: 10px; text-align: center; 
          line-height: 45px; color: #FFFDFD; margin-top:42px;cursor:pointer;" @click="openLoginupHandler">注册</div>
      </div>
      
    </div>
    <div style="width:100%; background: #FAA887; box-sizing: border-box;
    display: flex; flex-direction: column; align-items: center; 
    border-bottom:#6075F2 1px solid;">
      <div style="height: 400px; width: 80%;">
        <div style="display: flex; flex-direction: row; align-items: center; margin-top:50px;">
          <img src="../assets/logo.png" alt="" style="height:60px;">
          <div style="width: 81px; height: 28px; font-size: 28px; font-family: Helvetica; font-weight: bold;
            line-height: 28px; color: #FFFFFF; margin-left:18px;">UIdance</div>
        </div>
        <div style="font-size: 16px; font-weight: 600; line-height: 38px; color: #FFFFFF; margin-top:20px;">联系我们</div>
        <div style="font-size: 16px; font-weight: 600; line-height: 38px; color: #FFFFFF;">hr@uidance.cn</div>
        <div style="font-size: 16px; font-weight: 600; line-height: 38px; color: #FFFFFF;">深圳市唯乐聚科技有限公司 © 2021 . All rights reserved.</div>
        <a style="font-size:14px;
                font-family:MicrosoftYaHei;
                line-height:19px; color: #FFFFFF;" target="_blank"  href="http://beian.miit.gov.cn">粤ICP备2021107619号-1</a>
        <!-- <div style="width:300px;margin:0 auto; padding:20px 0; margin-bottom: 22px;">
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011"
                style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                <img src="../assets/icon-beian.png" style="float:left;" />
                <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">粤公网安备
                    3101100号</p>
            </a>
        </div> -->
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  data: function () {
        return {
          closeLogin: true,
          isLogin: true,
        }
  },
  methods: {
    downloadHandler: function(){
      // window.location.href = "../assets/uidance.sketchplugin.zip";
      let a = document.createElement('a')
      // a.href ="../assets/uidance.sketchplugin.zip"
      a.href ="/download"
      a.download = "uidance.sketchplugin.zip"
      a.click();
    },
    clickHandler: function(){
      window.alert("服务器正在升级，请稍后...")
    },
    closeHandler: function(){
      this.closeLogin = true;
    },
    openLoginHandler: function(){
      this.closeLogin = false;
      this.isLogin = true;
    },
    openLoginupHandler: function(){
      this.closeLogin = false;
      this.isLogin = false;
    },
    toLoginupHandler: function(){
      this.isLogin = false
    },
    toLogininHandler: function(){
      this.isLogin = true
    },
    logupHandler: function() {
      window.alert("注册成功")
    },
    loginHandler: function(){
      window.alert("登录成功")
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello{
  width:100%;
  height:100%;
}
</style>
